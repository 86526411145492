@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import '~animate.css/animate.min';
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';


@font-face {
        font-family: Poppins;
        src: url(/assets/fonts/Poppins/Poppins-Bold.ttf) format('ttf'),;
        font-display: swap;
        font-weight: 700;
}
@font-face {
    font-family: Inter;
    src: url(/assets/fonts/Inter/static/Inter-Regular) format('ttf'),;
    font-display: swap;
    font-weight: 400;
}

body {
    font-size: 18px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    color:#29362F;
    background: linear-gradient(23.31deg, #FFFFFF 26.06%, rgba(255, 255, 255, 0) 84.75%), #F1F7F8;
    background: #F3F8F9;
}
body::-webkit-scrollbar {
    display: none;
  }
//   this class is used in header to stop the overflow when navbar is open
.body-overflow{
    overflow: hidden;
}
.btn {
    cursor: pointer;
}
.btns {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: #114F5C;
    font-style: normal;
    font-size: 13px;
    line-height: 18px;
    border-radius: 4px;
    background-color: #fff;
    text-align: center;
    border: none;
    cursor: pointer;
}
h1,h2,h3,h4,h5,h6 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
}
h1 {
    font-size: 48px;
    line-height: 60px;
    font-weight: 600;
}
h2 {
    font-size: 36px;
    line-height: 46px;
    font-weight: 600;
    color: #29362F;
}
p {
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
}
.card {
    background-color: #fff;
    border-radius: 10px;
    border: none;
}
.card h4 {
    font-size: 20px;
    line-height: 29px;
    letter-spacing: -0.2px;
    color: #29362F;
    font-weight: 600;
}
.card p {
    font-family: Inter;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #29362F;
    opacity: 0.8;
}
.card a {
    font-family: Poppins;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.1px;
    color: #114F5C;
    text-decoration: none;
}
section p {
    font-family: Inter;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: -0.3px;
    color: #000000;
}
.badge-primary {
    background-color: #E3EFF2;
    border-radius: 3px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: #114F5C;
    padding: .20em .63em;
    margin-right: 5px;
    margin-top: 10px;
}
.errorLabel{
    color: #EF8265 !important;
}
.errorBorder{
    border: none !important;
    border-bottom: 1px solid #EF8265 !important;
}
.errorMsgBorder{
    border: 1px solid #EF8265 !important;
}
a {
    text-decoration: none;
}
input::placeholder{
    background: transparent !important;
}
input:focus-visible {
    outline: none !important;
    background-color: transparent !important;
}
.section2 .owl-theme .owl-nav {
   display: none;
}
.section3 .owl-theme .owl-nav {
    display: none;
}
.sub-solution-cards .owl-theme .owl-nav {
    display: none;
}
.hero-section-common{
    .owl-theme .owl-nav {
        display: none;
    }
}
.section3 .owl-theme .owl-dots {
    text-align: center;
    margin-top: 20px; 
}
.owl-theme .owl-dots .owl-dot span {
    width: 10px;
    height: 10px;
    margin: 5px 7px;
    background: #114F5C;
    opacity: 0.3;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: #114F5C;
    opacity: 1;
}
    .owl-theme .owl-nav {
        margin-top: 10px;
        text-align: center;
        position: absolute;
        top: 3%;
        right: 13%;
    }
    .owl-theme .owl-nav [class*=owl-] {
        height: 40px;
        width: 40px;
        padding: 5px;
        border-radius: 50%;
        background: #fff;
        border: 1px solid #acacac;
        opacity: 0.7;
    }
    .owl-theme .owl-nav [class*=owl-]:hover {
        background: #fff;
        color: #FFF;
        text-decoration: none;
    }
.owl-theme .owl-dots {
    text-align: center;
    -webkit-tap-highlight-color: transparent;
    margin-top: 20px;
}
.section2 .owl-theme .owl-dots {
    margin-top: 0px;
}

.hero-section-common .owl-carousel .owl-item {
    // width: 300px !important;
    // margin-left: 15px;
    // margin-right: 15px;
}
.sub-solution-cards .owl-carousel .owl-item {
    margin: 20px 0px;
}

// pagination controls
.paginationControls{
    font-family: Poppins;
    font-weight: 400;
    font-size: 16px;
    margin: 30px auto 50px;
    text-align: center;
    color: #114F5C;
    .ngx-pagination{ 
        padding-left: 0 !important;
        .current {
            padding: 3px 12px;
            background: transparent;
            cursor: default;
            border: 1px solid #114f5c;
            color: #114F5C;
            border-radius: 50%;
        }
        li{
            margin: 0 10px;
            font-family: Poppins;
            font-weight: 400;
            font-size: 16px;
            color: #114F5C;
        }
        .disabled {
            opacity: 0.5;
        }
    }
}
@media (max-width: 1500px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1290px;
    }
}
// recently this is changed because they need 50px pad on both side in i-pad so any problem occurs handle this first 
// @media (max-width: 1280px){
//     .container, .container-lg, .container-md, .container-sm, .container-xl {
//         max-width: 1180px;
//     }
// }
// @media (max-width:992px) {
//     .container, .container-lg, .container-md, .container-sm, .container-xl {
//         max-width: 720px;
//     }
// }
@media (min-width: 1500px){
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1420px;
    }
}
@media (min-width: 1680px){
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1540px;
    }
}

@media (max-width:1025) {
    .approach .testimonial-mobile .owl-theme .owl-nav {
        display: none !important;
    }
    
}

@media (min-width:505px) {
    .owl-theme .owl-nav {
        display: none;
        .mobile-dashed{
            display: none;
        }
    }
}
@media (max-width:504px) {
    .owl-theme .owl-nav {
        display: block;
        .mobile-dashed{
            display: block;
            width: 25px;
            height: 25px;
            position: absolute;
            top: 5%;
            right: 3%;
            transform: matrix(1,-.1,-.1,1,0,0);
        }
    }
}
@media only screen and (max-width: 600px) {
    .custom--container{
        max-width: 100%;
    }
  }
@media (max-width:500px) {
    h1 {
        font-size: 32px;
        line-height: 37px;
    }
    p {
        font-size: 16px;
        line-height: 27px;
    }
    h2 {
        font-size: 24px;
        line-height: 37px;
    }   
    .custom--container{
        width: 100%;
        padding-inline: 10px;
    }
    .owl-theme .owl-nav [class*=owl-] {
        opacity: 0.9 !important;
    }
    .owl-theme .owl-nav {
        display: none !important;
        top: 2.5% !important;
        right: 9% !important;
        .testimonial-arrows{
            width: 8px !important;
            height: 12px !important;
        }
    }
    .owl-theme .owl-nav .owl-prev{
        padding: 7px 7px 5px 5px !important;
    }
    .owl-theme .owl-nav .owl-next{
        padding: 5.5px 4px 5px 5px !important;
    }
}

// Custom Container

.custom--container{
    max-width: 1220px;
    margin-inline: auto;
}

.hero-section-case-studies {
    .hero-content {
        h3 {
            font-weight: 600;
            font-size: 32px;
            line-height: 53px;
            color: #29362F;
            margin-bottom: 30px;
            margin-top: 24px;
        }
        .author {
            display: flex;
            align-items: end;
            margin-bottom: 15px;
            img {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                margin-right: 15px;
            }
            .author-details {
                h4 {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 26px;
                    letter-spacing: -0.19992px;
                    color: #29362F;
                    margin-bottom: 4px;
                }
                p {
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 19px;
                    letter-spacing: -0.1px;
                    color: #114F5C;
                }
            }
        }
        .badge {
            margin-right: 25px;
        }
    }
}
section {
    .social-links {
        display: grid;
        span {
            font-family: Inter;
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 12px;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: #114F5C;
            margin-bottom: 20px;
        }
        img {
            cursor: pointer;
            height: 40px;
            width: 40px;
            margin-bottom: 12px;
        }
    }
    .mobile-none .social-links{
        margin-left: 40px;
    }
    .section-content {
        p {
            font-weight: 400;
            font-size: 15px !important;
            line-height: 24px;
            letter-spacing: -0.2px;
            color: #29362F;
            .summary-text {
                font-family: Poppins;
                font-weight: 600;
                font-size: 20px;
                line-height: 29px;
                letter-spacing: -0.2px;
                color: #114F5C;
                opacity: 1 !important;
            }
        }
        hr {
            border: 1px solid #114F5C;
            margin-top: 24px;
            margin-bottom: 39px;
        }
        h2 {
            margin-bottom: 29px;
        }
        h4 {
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            letter-spacing: -0.4px;
            color: #000000;
            margin-bottom: 39px;
        }
        .highlight {
            color: #114F5C;
            margin-bottom: 60px;
            border-left: 6px solid #114F5C;
            padding-left: 72px;
            padding-right: 70px;
        }
        ul {
            margin-bottom: 69px;
            margin-top: 5px;
            padding-left: 20px;
            li {
                font-family: Inter;
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 24px;
                color: #29362F;
            }
        }
        .heading1 {
            margin-bottom: 12px;
        }
        img {
            width: 790px;
            height: 465px;
            margin-bottom: 70px;
        }
        .video{
            width: -webkit-fill-available !important;
            iframe{
                width: 100% !important;
                height: 450px !important;
            }
        }
        .contact {
            background: linear-gradient(96.75deg, #114F5C 18.96%, rgba(41, 54, 47, 0) 120.65%), #93BD89;
            border-radius: 10px;
            padding: 50px 60px 50px 70px;
            margin-top: 55px;
            margin-bottom: 50px;
            h3 {
                font-weight: 500;
                font-size: 24px;
                line-height: 36px;
                letter-spacing: -0.4px;
                color: #FFFFFF;
            }
            .btns {
                padding: 15px 30px;
                &:hover{
                    background-color: #E3EFF2;
                }
                &:active{
                    background-color: #ffffff;
                }
                &:focus{
                    background-color: #BBD0D6;
                }
            }
        }
        .social {
            text-align: center;
            span {
                font-family: Inter;
                font-style: normal;
                font-weight: 500;
                font-size: 11px;
                line-height: 12px;
                letter-spacing: 1px;
                text-transform: uppercase;
                color: #114F5C;
                margin-bottom: 20px;
            }
            .links {
                display: flex;
                justify-content: center;
                margin-top: 20px;
                img {
                    cursor: pointer;
                    height: 40px;
                    width: 40px;
                    margin-left: 15px;
                    margin-right: 15px;
                }
            }
        }
    }
}

@media (max-width:1218px) {
    .hero-section-case-studies .hero-img img {
        position: relative;
        width: 100%;
        height: auto;
    }
    .hero-section-case-studies .hero-content {
        margin-top: 10px;
        padding: 0 30px;
        .partners{
            margin-left: 0;
            
        }
    }
    section .section-content img {
        width: 100%;
        margin-bottom: 70px;
    }
    section .sectionWidth .section-content .sectionWidth .video{
        width: -webkit-fill-available !important;
        iframe{
            width: 100% !important;
            height: 350px !important;
        }
    }
    .extra-pad-down{
        padding: 28.5px 0px;
    }
}
@media (max-width:1000px) {
    .hero-section-case-studies{ 
        .hero-img{ 
            padding: 0px 15px;
            margin-bottom: 25px;
            img {
                position: relative;
                width: 315px;
                height: 244px;
                left: -2px;
                top: 0;
                margin-left: 0px;
            }
        }
        .hero-content{
            padding: 0px 20px;
            h3 {
                font-size: 24px;
                line-height: 35px;
                margin-bottom: 35px;
                padding-right: 12px;
            }
            .partners {
                width: 120px;
                height: 67px;
                margin-bottom: 15px;
                margin-left: 0;
            }
            .author{
                p{
                    opacity: 0.9;
                }
            }
        }
    }
    section{
        .section-content {
            padding-right: 35px;
            padding-left: 15px;
            img {
                width: 100%;
                margin-bottom: 60px;
            }
            .video{
                width: -webkit-fill-available !important;
                iframe{
                    width: 100% !important;
                    height: 350px !important;
                }
            }
            h2 {
                margin-bottom: 10px;
            }
            h4{
                font-size: 20px;
                line-height: 26px;
                color: #29362F;
                margin-bottom: 22px;
            }
            .text1 {
                margin-bottom: 55px;
                .summary-text{
                    font-size: 16px;
                    line-height: 26px;
                }
            }
            .text2 {
                margin-bottom: 50px;
            }
            h4.mb-4{
                line-height: 35px;
            }
            .highlight {
                padding: 0px 28px;
            }
            .contact {
                padding: 38px 50px 45px 30px;
                margin-top: 35px;
                margin-bottom: 40px;
                h3 {
                    font-size: 20px;
                    line-height: 26px;
                }
            }
            .social {
                .links{
                    img {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
    
}
@media (max-width:500px) {
    section{
        .section-content {
            img {
                width: 100%;
                height: 195px;
                margin-bottom: 60px;
            }
            .video{
                width: -webkit-fill-available !important;
                iframe{
                    width: 100% !important;
                    height: 200px !important;
                }
            }
             
            h2 {
                margin-bottom: 10px;
            }
            h4{
                font-size: 20px;
                line-height: 26px;
                color: #29362F;
                margin-bottom: 22px;
            }
            .text1 {
                margin-bottom: 55px;
                .summary-text{
                    font-size: 16px;
                    line-height: 26px;
                }
            }
            .text2 {
                margin-bottom: 50px;
            }
            h4.mb-4{
                line-height: 35px;
            }
            .highlight {
                padding: 0px 28px;
            }
            .contact {
                padding: 38px 50px 45px 30px;
                margin-top: 35px;
                margin-bottom: 40px;
                h3 {
                    font-size: 20px;
                    line-height: 26px;
                }
            }
            .social {
                .links{
                    img {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}